import React, { useState } from 'react'
import { useAuth } from '../contexts/AuthContext'
import { Link, useNavigate  } from 'react-router-dom'
import "bootstrap/dist/css/bootstrap.min.css"
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Accordion from 'react-bootstrap/Accordion';
import Badge from 'react-bootstrap/Badge';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';


export default function Dashboard() {
  const [error, setError] = useState("")
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [id, setId] = useState("");
  const YOUTUBE_API_KEY = process.env.REACT_APP_YOUTUBE_API_KEY;
  const [subscribers, setSubscribers] = useState("n/a");
  const [views, setViews] = useState("n/a");
  const [videos, setVideos] = useState("n/a");

  async function handleLogout(){
    setError(" ")

    try {
      await logout();
      navigate("/login")
    } catch {
      setError("Failed to log out!")
    }
  }

  async function handleProfileNav(){
    setError(" ")

    try {
      navigate("/profile")
    } catch {
      setError("Failed to redirect to profile!")
    }
  }


  function AlertDismissibleExample() {
    const [show, setShow] = useState(true);
  
    if (error) {
      return (
        <Alert className='w-75 p-3 align-center m-auto mt-3' variant="danger" onClose={() => setShow(false)} dismissible>
          <Alert.Heading>Oh snap! You got an error!</Alert.Heading>
          <p>
            {error}
          </p>
        </Alert>
      );
    }
  }


  function handleChange(e){
    setId(e.target.value);
    console.log(e.target.value);
    
  }
  async function handleSubmit(e){
    e.preventDefault();
    

    fetch(`https://www.googleapis.com/youtube/v3/channels?part=statistics&id=${id}&key=${YOUTUBE_API_KEY}`)
    .then((data) => data.json())
    .then((result) => {
      setSubscribers(result.items[0].statistics.subscriberCount)
      setViews(result.items[0].statistics.viewCount)
      setVideos(result.items[0].statistics.videoCount)
    })
  }


  return (
    <>
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container>
        <Navbar.Brand href="#home">
        <img
              alt=""
              src="https://uploads-ssl.webflow.com/626d637326f5885bd8b56b80/628f6c8b11f04e3766393739_Relight%20Vector-07.svg"
              width="30"
              height="30"
              className="d-inline-block align-top mx-2"
            />
          
          Dashboard
          </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            <NavDropdown title="Profile" id="collasible-nav-dropdown">
              <NavDropdown.Item onClick={handleProfileNav}>View Profile</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item onClick={handleLogout}>
                Logout
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    <AlertDismissibleExample />

    <Container className="mt-3">
      <h2>Client123 Youtube</h2>
      <hr></hr>
      <h4>News  <Badge bg="secondary">2</Badge></h4>
      <Accordion defaultActiveKey="0">
      <Accordion.Item eventKey="0">
        <Accordion.Header>Welcome!</Accordion.Header>
        <Accordion.Body>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="1">
        <Accordion.Header>New!</Accordion.Header>
        <Accordion.Body>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
    <hr></hr>
    <Row>
      <Col className='col-auto'>
        <h4 className='mb-3'>Overall Statistics</h4>
      </Col>
      <Col className='col-auto'>
        <Button variant="secondary" onClick={handleShow} size="sm">
         select channel
        </Button>
      </Col>
    </Row>

    <Modal show={show} backdrop="static" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Select Channel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-2" style={{ maxWidth: "500px" }} controlId="channelUrl">
              <Form.Control type="text" placeholder="Enter Channel Id" name="id" onChange={handleChange} value={id} required/>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Form.Check type="checkbox" label="Save Channel URL (disabled)" name="save" disabled/>
            </Form.Group>
          <div className="modal-footer p-2">
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" className='mx-2' type="submit" onClick={handleClose && handleSubmit}>
            Submit
          </Button>
          </div>  
          
          </Form>  
        </Modal.Body>
          
      </Modal>
      

    <Row>
      <Col>
      <Card className="my-3" style={{ maxWidth: "300px" }}>
        <Card.Header>Views</Card.Header>
        <Card.Body>
          <Card.Title>{views}</Card.Title>
        </Card.Body>
      </Card>
      </Col>
      <Col>
      <Card className="my-3" style={{ maxWidth: "300px" }}>
        <Card.Header>Subscribers</Card.Header>
        <Card.Body>
          <Card.Title>{subscribers}</Card.Title>
        </Card.Body>
      </Card>
      </Col>
      <Col>
      <Card className="my-3" style={{ maxWidth: "300px" }}>
        <Card.Header>Videos</Card.Header>
        <Card.Body>
          <Card.Title>{videos}</Card.Title>
        </Card.Body>
      </Card>
      </Col>
    </Row>
    </Container>
    </>
  )
}